<template>
  <div class="flex-col space-y-20 group_15">
    <div class="flex-row space-x-20">
      <div class="flex-col section_6" v-if="identity == 0">
        <div class="flex-row space-x-10 group_17">
          <img src="../../../../public/static/students.png" class="image_17" />
          <span class="text_14">学员信息</span>
        </div>
        <div class="flex-row group_18">
          <img :src="userInfo.headimg" class="image_19" />
          <div class="flex-col group_19">
            <div class="flex-row group_20 ">
              <span class="text_16">学员账号：</span>
              <span class="text_17">{{userInfo.student_number}}</span>
            </div>
            <div class="flex-row group_21">
              <span class="text_18">手机号码：</span>
              <span class="text_19">{{userInfo.mobile}}</span>
            </div>
            <div class="flex-row group_22">
              <span class="text_20">工作单位：</span>
              <span class="text_21" v-if="userInfo.enterprise_name != ''">{{userInfo.enterprise_name}}</span>
              <span class="text_21" v-else>-</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col section_6" :data-s="identity" v-else="identity == 1">
        <div class="flex-row space-x-10 group_17">
          <img src="../../../../public/static/students.png" class="image_17" />
          <span class="text_14">企业信息</span>
        </div>
        <div class="flex-row group_18">
          <img :src="copyInfo.logo" class="image_19" />
          <div class="flex-col group_19">
            <div class="flex-row group_20 ">
              <span class="text_17">{{copyInfo.enterprise_name}}</span>
            </div>
            <div class="flex-row group_21">
              <span class="text_18">纳税人识别号：</span>
              <span class="text_18">{{copyInfo.taxpayer_number}}</span>
            </div>
            <div class="flex-row group_22">
              <span class="text_18">企业地址：</span>
              <span class="text_18">{{copyInfo.address}}</span>
            </div>
          </div>
        </div>
      </div>
      <el-carousel :interval="4000" @change="changeImg" trigger="click" class="image_20 click-cursor">
        <el-carousel-item v-for="(item,index)  in bannerList" :key="index">
          <el-image @click="urlImg($event)" :data-url="item.url" :class="className" style="width: 100%;"
            :src="item.imgpath" class="image_20" fit="cover"></el-image>
        </el-carousel-item>

      </el-carousel>
    </div>
    <div class="flex-col space-y-20">
      <div class="flex-col section_7" v-if="identity == 0">
        <div class="flex-row space-x-10 group_17">
          <img src="../../../../public/static/curriculum.png" class="image_17" />
          <span class="text_14">课程信息</span>
        </div>
        <div class="flex-row space-x-11 group_24">
          <div class="flex-row space-x-22 section_8 click-cursor" @click="goUrljiao()">
            <img src="../../../../public/static/wallet.png" class="image_22" />
            <div class="flex-col items-start space-y-8 group_25 ">
              <span class="text_23">0</span>
              <span class="text_24">待缴费</span>
            </div>
          </div>
          <div class="flex-row space-x-22 section_9 click-cursor" @click="goUrlxue()">
            <img src="../../../../public/static/learning.png" class="image_22" />
            <div class="flex-col items-start space-y-8 group_26 ">
              <span class="text_25">0</span>
              <span class="text_26">学习中</span>
            </div>
          </div>
          <div class="flex-row space-x-22 section_10 click-cursor" @click="goUrlpei()">
            <img src="../../../../public/static/train.png" class="image_22" />
            <div class="flex-col items-start space-y-8 group_27 ">
              <span class="text_27">0</span>
              <span class="text_28">即将培训</span>
            </div>
          </div>
          <div class="flex-row space-x-22 section_11 click-cursor" @click="goUrlkao()">
            <img src="../../../../public/static/examination.png" class="image_22" />
            <div class="flex-col items-start space-y-8 group_28 ">
              <span class="text_29">0</span>
              <span class="text_30">即将考试</span>
            </div>
          </div>
          <div class="flex-row space-x-22 section_12 click-cursor" @click="goUrlzheng()">
            <img src="../../../../public/static/certificate.png" class="image_22" />
            <div class="flex-col items-start space-y-8 group_29">
              <span class="text_31">0</span>
              <span class="text_32">已发证</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col section_7" v-if="identity == 1">
        <div class="flex-row space-x-10 group_17">
          <img src="../../../../public/static/curriculum.png" class="image_17" />
          <span class="text_14">员工统计</span>
        </div>
        <div class="flex-row space-x-11 group_24">
          <div class="flex-row space-x-22 section_8 click-cursor" >
            <img src="../../../../public/static/wallet.png" class="image_22" />
            <div class="flex-col items-start space-y-8 group_25 ">
              <span class="text_23">0</span>
              <span class="text_24">今日新增员工(人)</span>
            </div>
          </div>
          <div class="flex-row space-x-22 section_9 click-cursor" >
            <img src="../../../../public/static/learning.png" class="image_22" />
            <div class="flex-col items-start space-y-8 group_26 ">
              <span class="text_25">0</span>
              <span class="text_26">现有总员工(人)</span>
            </div>
          </div>

          <div class="flex-row space-x-22 section_11 click-cursor" >
            <img src="../../../../public/static/examination.png" class="image_22" />
            <div class="flex-col items-start space-y-8 group_28 ">
              <span class="text_29">0</span>
              <span class="text_30">代缴费</span>
            </div>
          </div>
          <div class="flex-row space-x-22 section_12 click-cursor" >
            <img src="../../../../public/static/certificate.png" class="image_22" />
            <div class="flex-col items-start space-y-8 group_29">
              <span class="text_31">0</span>
              <span class="text_32">已发证</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col section_13">
        <div class="flex-row justify-between">
          <div class="flex-row space-x-10 group_31">
            <img src="../../../../public/static/news.png" class="image_17" />
            <span class="text_33">最新消息</span>
          </div>
          <div class="flex-row space-x-5 group_32">
            <span class="text_34" @click="goMessageUserBtn">历史消息</span>
            <img src="../../../../public/static/view.png" class="image_29" />
          </div>
        </div>

        <div class="flex-col space-y-21 group_33" v-for="item in messageUser">
          <div class="flex-row justify-between">
            <div class="flex-col items-center text-wrapper">
              <div class="section_5" v-if="item.status == 0">
                <!---->
              </div><span class="text_35">{{item.name}}</span>
            </div>
            <span class="text_36">{{item.create_time}}</span>
          </div>
          <span class="text_37">{{item.content}}</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        copyInfo: [],
        userInfo: [],
        bannerList: [], //轮播图地址
        className: "", //轮播图名字
        messageUser: [], //系统消息
        identity: 0
      };
    },
    beforeCreate() {},
    created() {
      let that = this;
      that.getUser();
      that.getFlash();
      that.getMessageUser();
    },
    methods: {
      getUser() {
        let that = this;
        let token = sessionStorage.getItem('token');
        that.identity = sessionStorage.getItem('identity');

        that.$request.getUserInfo().then((res) => {
          if (res.code == 200) {
            //登录成功
            console.log(res.datas);
            if(that.identity == 0){
              that.userInfo = res.datas;
               return false;
            }
            if(that.identity == 1){
              that.copyInfo = res.datas;
               return false;
            }
          }
        }).catch((err) => {
          let that = this;
          that.$myMessage.error('系统错误');
        });

      },
      getFlash() {
        let that = this;
        that.$request.getFlash({
          cid: 1
        }).then((res) => {
          if (res.code == 200) {
            that.bannerList = res.datas;
          }
        }).catch((err) => {
          let that = this;
          that.$myMessage.error('系统错误');
        });
      },
      //轮播图切换
      changeImg(e) {
        console.log(e, "当前下标"); //可以打印当前轮播图下标
      },
      //获取系统消息
      getMessageUser() {
        let that = this;
        let param = {
          page: 1,
          limit: 8,
        };
        that.$request.getMessageUser(param).then((res) => {
          if (res.code == 200) {
            that.messageUser = res.datas;
          }
        }).catch((err) => {
          let that = this;
          that.$myMessage.error('系统错误');
        });
      },
      goMessageUserBtn() {
        let that = this;
        that.$router.push({
          name: 'userMessageuser'
        });
      },
      goUrlzheng() {
        let that = this;
        that.$router.push({
          name: 'userIndexInfo',
          query: {
            selectId: 2
          }

        });
      },
      goUrlkao() {
        let that = this;
        that.$router.push({
          name: 'userTrainingList'
        });
      },
      goUrlpei() {
        let that = this;
        that.$router.push({
          name: 'userTrainingRecords'
        });
      },
      goUrlxue() {
        let that = this;
        that.$router.push({
          name: 'userTrainingList'
        });

      },
      goUrljiao() {
        let that = this;
        that.$router.push({
          name: 'userOrder',

        });
        query: {
          is_pay: 0
        }
      },
      urlImg(e) {
        let that = this;
        window.open(e.target.dataset.url, "_blank");
      },
      //退出登录
      logout() {
        let that = this;
        // 这里需要elementui的支持，如果使用其他界面组件自行替换即可
        that.$confirm('是否退出登录？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //清除登录状态缓存
          window.sessionStorage.clear()
          that.$store.commit('changeLoginState', 0);
          //element-ui提示
          that.$myMessage.success('退出登录');
          that.$router.replace('/login')
        }).catch(() => {
          // 如果取消跳转地址栏会变化，这时保持地址栏不变
          window.history.go(1)
        })

      },
    }
  }
</script>

<style scoped>
  .click-cursor {
    cursor: pointer;
  }

  .el-carousel__container {
    height: 100% !important;
  }

  .space-x-5>*:not(:first-child) {
    margin-left: 0.31rem;
  }

  .group_15 {
    padding: 1.25rem 1.25rem 1.88rem;
    flex: 1 1 auto;
    width: 0;
  }

  .section_5 {
    margin-top: 0.11rem;
    flex-shrink: 0;
    background-color: #f26061;
    border-radius: 50%;
    width: 0.63rem;
    height: 0.63rem;
  }

  .section_6 {
    padding: 0.94rem 0.94rem 0;
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 0.63rem;
    height: 16.25rem;
    margin: 0 0.94rem 0.94rem 0;
    width: 41%;
  }

  .space-x-10>*:not(:first-child) {
    margin-left: 0.63rem;
  }

  .group_17 {
    align-self: flex-start;
    overflow: hidden;
    width: 7.88rem;
  }

  .image_17 {
    flex-shrink: 0;
    border-radius: 0.63rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .text_14 {
    align-self: center;
    color: #0074ff;
    font-size: 1.19rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 1.13rem;
  }

  .group_18 {
    padding: 1.56rem 0 2.5rem;
    width: 100%;
  }

  .image_19 {
    width: 6.25rem;
    height: 8.75rem;
  }

  .group_19 {
    margin: 0.25rem 0 0.38rem 1.25rem;
    width: 20.94rem;
  }

  .group_20 {
    padding-bottom: 1.28rem;
  }

  .text_16 {
    color: #545964;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
  }

  .text_17 {
    margin-top: 0.22rem;
    color: #000000;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 0.75rem;
  }

  .group_21 {
    padding: 0.25rem 0 0.34rem;
    align-self: flex-start;
    overflow: hidden;
    /* width: 12.63rem; */
  }

  .text_18 {
    flex-shrink: 0;
    color: #545964;
    font-size: 0.8rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
  }

  .text_19 {
    margin-top: 0.22rem;
    flex-shrink: 0;
    color: #000000;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 0.75rem;
  }

  .group_22 {
    margin-top: 0.94rem;
    /* overflow: hidden; */
    height: 3.25rem;
  }

  .text_20 {
    margin-top: 0.25rem;
    flex-shrink: 0;
    color: #545964;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
  }

  .text_21 {
    margin-right: 0.78rem;
    flex-shrink: 0;
    color: #000000;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 1.56rem;
    width: 70%;
  }

  .image_20 {
    /* flex: 1 1 auto; */
    width: 58.3333%;
    height: 16.25rem;
    border-radius: 0.6rem;
    border-radius: 0.63rem;
    overflow: hidden;
    display: block;
    box-sizing: border-box;
    float: left;
  }

  .section_7 {
    padding: 0.94rem 0.63rem 0 0.94rem;
    background-color: #ffffff;
    border-radius: 0.63rem;
    /* margin-top: 0.75rem; */

  }

  .space-x-11>*:not(:first-child) {
    margin-left: 0.69rem;
  }

  .group_24 {
    padding: 1.56rem 0;
  }

  .space-x-22>*:not(:first-child) {
    margin-left: 1.38rem;
  }

  .section_8 {
    padding: 1.25rem 1.25rem;
    flex: 1 1 13.47rem;
    background-color: #f0f7ff;
    border-radius: 0.94rem;
    height: 5.94rem;
  }

  .image_22 {
    border-radius: 0.94rem;
    width: 3.44rem;
    height: 3.44rem;
    align-self: center;
  }

  .space-y-8>*:not(:first-child) {
    margin-top: 0.5rem;
  }

  .group_25 {
    align-self: center;
  }

  .text_23 {
    color: #0074ff;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1rem;
  }

  .text_24 {
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
  }

  .section_9 {
    padding: 1.25rem 1.25rem;
    flex: 1 1 13.47rem;
    background-color: #fff7ec;
    border-radius: 0.94rem;
    height: 5.94rem;
  }

  .group_26 {
    align-self: center;
  }

  .text_25 {
    color: #e29835;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1rem;
  }

  .text_26 {
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
  }

  .section_10 {
    padding: 1.25rem 1.25rem;
    flex: 1 1 13.47rem;
    background-color: #fff2f3;
    border-radius: 0.94rem;
    height: 5.94rem;
  }

  .group_27 {
    align-self: center;
  }

  .text_27 {
    color: #f26061;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1rem;
  }

  .text_28 {
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
  }

  .section_11 {
    padding: 1.25rem 1.25rem;
    flex: 1 1 13.47rem;
    background-color: #eef8e7;
    border-radius: 0.94rem;
    height: 5.94rem;
  }

  .group_28 {
    align-self: center;
  }

  .text_29 {
    color: #7ac756;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1rem;
  }

  .text_30 {
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
  }

  .section_12 {
    padding: 1.25rem 1.25rem;
    flex: 1 1 13.47rem;
    background-color: #fdfcee;
    border-radius: 0.94rem;
    height: 5.94rem;
  }

  .group_29 {
    align-self: center;
  }

  .text_31 {
    color: #f4e929;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1rem;
  }

  .text_32 {
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
  }

  .section_13 {
    padding: 0.94rem 0.88rem 3.31rem;
    background-color: #ffffff;
    border-radius: 0.63rem;
    margin-top: 0.75rem
  }

  .group_31 {
    overflow: hidden;
    width: 7.88rem;
    height: 2.5rem;
  }

  .text_33 {
    align-self: center;
    color: #0074ff;
    font-size: 1.19rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 1.13rem;
  }

  .group_32 {
    padding: 0.19rem 0 0.25rem;
    align-self: center;
    overflow: hidden;
    width: 5.25rem;
    height: 1.44rem;
  }

  .text_34 {
    flex-shrink: 0;
    color: #0074ff;
    font-size: 1rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.97rem;
    cursor: pointer;
  }

  .image_29 {
    flex-shrink: 0;
    width: 0.94rem;
    height: 0.94rem;
  }

  .space-y-21>*:not(:first-child) {
    margin-top: 1.31rem;
  }

  .group_33 {
    margin-top: 2.25rem;
    margin: 1.25rem 0.94rem 0;
    padding-bottom: 1.56rem;
    overflow: hidden;
    border-bottom: solid 0.0625rem #d8d8d8;
  }

  .text-wrapper {
    padding: 0.22rem 0 0.31rem;
    overflow: hidden;
    /* width: 6.75rem; */
    height: 1.63rem;
    flex-direction: row;
  }

  .text_35 {
    color: #000000;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
    margin-left: 0.63rem;
  }

  .text_36 {
    margin-right: 0.72rem;
    align-self: center;
    color: #9a9da7;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    line-height: 0.63rem;
  }

  .text_37 {
    align-self: flex-start;
    color: #545964;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    padding-left: 1.25rem;
  }

  .group_35 {
    margin-top: 2.81rem;
  }

  .space-y-22>*:not(:first-child) {
    margin-top: 1.38rem;
  }

  .group_36 {
    padding-bottom: 1.56rem;
    overflow: hidden;
    border-bottom: solid 0.024rem #d8d8d8;
  }

  .group_38 {
    padding: 0.22rem 0 0.31rem;
    overflow: hidden;
    width: 8rem;
    height: 1.63rem;
  }

  .section_14 {
    margin-top: 0.41rem;
    flex-shrink: 0;
    background-color: #f26061;
    border-radius: 50%;
    width: 0.63rem;
    height: 0.63rem;
  }

  .text_38 {
    color: #000000;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .text_40 {
    margin-right: 0.66rem;
    align-self: center;
    color: #9a9da7;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    line-height: 0.63rem;
  }

  .text_42 {
    align-self: flex-start;
    color: #545964;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.88rem;
  }

  .group_40 {
    padding: 0.22rem 0 0.34rem;
    overflow: hidden;
    width: 5.75rem;
    height: 1.63rem;
  }

  .text_45 {
    align-self: flex-start;
    color: #545964;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.91rem;
  }

  .text_48 {
    align-self: flex-start;
    color: #545964;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.91rem;
  }

  .space-y-19>*:not(:first-child) {
    margin-top: 1.19rem;
  }

  .group_42 {
    padding-bottom: 0.19rem;
    overflow: hidden;
    height: 6.94rem;
  }

  .group_44 {
    padding: 0.22rem 0 0.34rem;
    overflow: hidden;
    width: 8rem;
    height: 1.63rem;
  }

  .text_49 {
    color: #000000;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .text_50 {
    margin-right: 0.66rem;
    align-self: center;
    color: #9a9da7;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    line-height: 0.63rem;
  }

  .text_51 {
    color: #545964;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.31rem;
  }
</style>
